import React, {useState} from "react";
import {faq} from './data';

const styles = {
  container: {
    maxWidth: 600,
    margin: "0 auto",
    paddingTop: 80,
  },
  q: {
    cursor: "pointer",
    fontWeight: 400,
    userSelect: "none",
    marginBottom: 16,
    lineHeight: "20px"
  },
  a: {
    paddingLeft: 8,
    marginBottom: 18,
    // marginTop: 6,
    color: "#4a4a4a",
    userSelect: "none"
  },
  questionsBox: {
    backgroundColor: "#fbfbfa",
    padding: 30,
    paddingBottom: 14
  }
};

function Item(props) {
  const {data} = props;
  const [open, setOpen] = useState(false);

  return (
    <div>
      <div
        style={styles.q}
        onClick={()=>setOpen(!open)}
      >
        {data.q}
      </div>

      {open?<div style={styles.a}>{data.a}</div>:null}
    </div>
  )
}

export function FAQ() {
  return <div style={styles.container} id={"faq"}>
    <h2 className="u-font-script text-center u-m-b-5">More Info</h2>

    <div style={styles.questionsBox}>
      {faq.map(item => <Item data={item} key={item.q} />)}
    </div>
  </div>;
}