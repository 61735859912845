import React from "react";

export function Countdown() {

  return (
    <div className="row">
      <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
        <ul className="counter-list js-counter clearfix" data-date="2019/07/26">
          <li className="counter-list__item">
            <span className="js-counter-days">124</span>
            <p>days</p>
            <ion-icon name="heart" class="icon icon--sm icon--primary"/>
          </li>
          <li className="counter-list__item">
            <span className="js-counter-hours">20</span>
            <p>hours</p>
            <ion-icon name="heart" class="icon icon--sm icon--primary"/>
          </li>
          <li className="counter-list__item">
            <span className="js-counter-minutes">30</span>
            <p>minutes</p>
            <ion-icon name="heart" class="icon icon--sm icon--primary"/>
          </li>
          <li className="counter-list__item">
            <span className="js-counter-seconds">50</span>
            <p>seconds</p>
          </li>
        </ul>
      </div>
    </div>
  );
}