import React from "react";
import {useScreenWidth} from "./hooks/useScreenWidth";

export function Registry() {
  const screenWidth = useScreenWidth();

  return (
    <section
      className="registry js-section"
      id="registry"
      style={{
        marginTop: 60
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-10 offset-xl-1">
            <div
              className="rsvp-box u-bg-white"
              style={{
                padding: "20px 0 10px 0"
              }}
            >
              <div className="js-form-wrapper">
                <div className="text-center">
                  <h2 className="rsvp-box__title u-font-script">Registry</h2>

                  <p className="rsvp-box__subtitle">
                    Choose a registry below:
                  </p>

                  <div style={{textAlign: "center"}}>
                    <a
                      target="_blank"
                      href="http://zola.com/registry/dakhwedding"
                    >
                      <img width={160} src={"/images/zola.jpg"}/>
                    </a>

                    <a
                      style={{marginLeft: screenWidth>=400?40:0}}
                      target="_blank"
                      href="https://www.anthropologie.com/registry/listing?registryId=MZA1YWM3MDVJ"
                    >
                      <img width={160} src={"/images/anthropologie.jpg"}/>
                    </a>
                  </div>

                  <div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}