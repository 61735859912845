// noinspection JSUnresolvedFunction
const firebase = require("firebase/app");
// noinspection JSUnresolvedFunction
require("firebase/firestore");

var config = {
  apiKey: "AIzaSyACjfHeg3vgx9g5rgE6FgU9bQ38F-jbDXY",
  authDomain: "wedding-12b89.firebaseapp.com",
  databaseURL: "https://wedding-12b89.firebaseio.com",
  projectId: "wedding-12b89",
  storageBucket: "wedding-12b89.appspot.com",
  messagingSenderId: "639026335606"
};
firebase.initializeApp(config);

const db = firebase.firestore();
const rsvpDb = db.collection("rsvp");

export {rsvpDb};