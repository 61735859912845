import React from "react";

const styles = {
  container: {
    marginBottom: 20,
    fontSize: 16
  },
  tagLine: {
    // fontSize: 14,
    // fontWeight: 700,
  },
  link: {
    fontSize: 16,
    position: "relative",
    top: -5,
    color: "rgb(238, 150, 105)"
  }
};

export function WhereBlock(props) {
  const {item} = props;
  const {name, tagLine, description, link, linkDisplay} = item;

  return <div style={styles.container}>

    {name ? (
      <h4>
        {name}
        {link ? <div><a href={link} target={"_blank"} style={styles.link}>{linkDisplay?linkDisplay:link}</a></div> : null}
      </h4>
    ) : null}

    {tagLine ? <p style={styles.tagLine}>{tagLine}</p> : null}

    {description}
  </div>;
}