import React from "react";

export function Slide(props) {
  const {image, position} = props;

  let additionalStyles = {};

  if ( position ) {
    additionalStyles = {
      backgroundPosition: position
    }
  }

  return <div
    style={{backgroundImage: `url('${image}')`, ...additionalStyles}}
    className="hero hero--slide1 js-hero-slide d-flex align-items-center justify-content-center">
    <div className="text-center" style={{height: 400, paddingTop: 300}}>
      {/*<img src="assets/img/wreath-white.svg" className="hero__wreath hero__wreath--up d-inline"*/}
           {/*alt="Sarah and Mike"/>*/}
      <h1 className="hero__title u-font-script" data-aos="zoom-in" style={{marginBottom: 0}}>Sarah & Steve</h1>
      <p className="hero__date" data-aos="zoom-in" data-aos-delay="400">July 26, 2019</p>
      {/*<img src="assets/img/wreath-white.svg" className="hero__wreath hero__wreath--down d-inline"*/}
           {/*alt="Sarah and Mike"/>*/}
    </div>
  </div>;
}