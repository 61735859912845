import React, {useState} from "react";
import Switch from 'react-ios-switch';
import {rsvpDb} from './db';


export function RSVP() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [guestNum, setGuestNum] = useState("");
  const [guestMessage, setGuestMessage] = useState("");
  // const [attending, setAttending] = useState("ceremony");
  const [cantMake, setCantMake] = useState(false);
  const [finished, setFinished] = useState(false);

  if (finished) return null;

  return (
    <section
      className="rsvp js-section"
      id="rsvp"
      style={{
        marginBottom: 60
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-10 offset-xl-1">
            <div
              className="rsvp-box u-bg-white"
              // style={{
              //   padding: "20px 0 10px 0"
              // }}
            >
              <div className="js-form-wrapper">
                <div className="text-center">
                  <h2 className="rsvp-box__title u-font-script">be our guest!</h2>
                  <p className="rsvp-box__subtitle">Please confirm your arrival by <mark
                    className="font-weight-bold">April 2, 2019</mark></p>

                  <p className="rsvp-box__subtitle">
                    RSVP below
                  </p>
                </div>

                <form action="javascript:void(0);" className="rsvp-form js-form" id="rsvp-form">
                  <div className="d-md-flex justify-content-between">
                    <div className="rsvp-form__left">
                      <div className="rsvp-form-field">
                        <input
                          type="text"
                          name="fullname"
                          placeholder="Guest Names"
                          value={name}
                          onChange={(event) => setName(event.target.value)}
                          required
                        />
                      </div>
                      <div className="rsvp-form-field">
                        <input
                          type="email"
                          name="email"
                          placeholder="Your E-mail"
                          className="js-email"
                          value={email}
                          onChange={(event) => setEmail(event.target.value)}
                          required
                        />
                        <p className="rsvp-form-field__error js-email-error">E-mail is incorrect.</p>
                      </div>
                      <div className="rsvp-form-field">
                        <input
                          type="number"
                          name="guests"
                          placeholder="Total number of guests"
                          value={guestNum}
                          onChange={(event) => setGuestNum(event.target.value)}
                          required
                        />
                      </div>
                      {/*<div className="rsvp-form-field">*/}
                        {/*<select*/}
                          {/*name="attending"*/}
                          {/*value={attending}*/}
                          {/*onChange={(event) => setAttending(event.target.value)}*/}
                          {/*required*/}
                        {/*>*/}
                          {/*<option value="" disabled>You are attending?</option>*/}
                          {/*<option value="all">Ceremony, reception & drinks the day after</option>*/}
                          {/*<option value="ceremony">Ceremony & Reception</option>*/}
                        {/*</select>*/}
                      {/*</div>*/}

                      {/*<div*/}
                        {/*style={{paddingLeft: 10, paddingTop: 0, borderBottom: "1px solid #898989"}}*/}
                      {/*>*/}
                        {/*<div*/}
                          {/*style={{display: "flex", flexDirection: "row", }}*/}
                        {/*>*/}
                          {/*<div>Can't make it</div>*/}
                          {/*<input*/}
                            {/*style={{width:"auto", marginLeft: 14, marginTop: 4, padding: 0, marginBottom: 14 }}*/}
                            {/*type={"checkbox"}*/}
                            {/*value={cantMake}*/}
                            {/*onChange={(event)=>setCantMake(event.target.checked)}*/}
                          {/*/>*/}
                        {/*</div>*/}
                      {/*</div>*/}

                      <div
                        style={{paddingLeft: 10, paddingTop: 4, paddingBottom: 6, borderBottom: "1px solid #898989"}}
                      >
                        <div
                          style={{display: "flex", flexDirection: "row", }}
                        >
                          <div>
                            Can't make it<br/>
                            {/*<span*/}
                              {/*style={{*/}
                                {/*fontSize: 12,*/}
                              {/*}}*/}
                            {/*>*/}
                              {/*Party begins: 7/27 5:30pm*/}
                            {/*</span>*/}
                          </div>
                          <Switch
                            style={{
                              marginLeft: 10
                            }}
                            onColor={"#ee9669"}
                            checked={cantMake}
                            onChange={setCantMake}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="rsvp-form__right">
                      <div className="rsvp-form-field h-100">
                        <textarea
                          rows="4"
                          placeholder="Additional information (dietary requirements, etc.)"
                          className="h-100"
                          name="message"
                          value={guestMessage}
                          onChange={(event) => setGuestMessage(event.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="rsvp-form-submit js-submit"
                    onClick={async () => {
                      if (!name || !email || !guestNum) return false;

                      await rsvpDb.add({
                        cantMake,
                        email,
                        guestMessage,
                        name,
                        guestCount: guestNum,
                        time: Date.now()
                      });

                      alert("Thank you for the RSVP!");

                      setFinished(true);
                    }}
                  >
                    <ion-icon name="heart" class="icon icon--sm icon--white animation-pulse"/>
                    <span style={{color: "#FFF"}}>Send</span>
                    <ion-icon name="heart" class="icon icon--md icon--white animation-pulse"/>
                  </button>
                </form>
              </div>
              {/*<div className="rsvp-confirmation text-center js-form-confirmation">*/}
              {/*<h4>We recieved your reservation.</h4>*/}
              {/*<span className="u-font-script u-h3">thank you!</span>*/}
              {/*<ion-icon name="heart" class="icon icon--md icon--primary"/>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}