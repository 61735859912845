import React from "react";

export function Footer() {
  return <footer className="footer u-bg-grey">
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <div className="text-center">
            <h2 className="u-font-script">thank <ion-icon name="heart"
  class="icon icon--md icon--primary animation-pulse"/> you!
            </h2>
            {/*<ul className="footer__social">*/}
              {/*<li><a href="#">*/}
                {/*<ion-icon name="logo-instagram" class="icon icon--sm icon--black"/>*/}
              {/*</a></li>*/}
              {/*<li><a href="#">*/}
                {/*<ion-icon name="logo-facebook" class="icon icon--sm icon--black"/>*/}
              {/*</a></li>*/}
              {/*<li><a href="#">*/}
                {/*<ion-icon name="logo-youtube" class="icon icon--sm icon--black"/>*/}
              {/*</a></li>*/}
            {/*</ul>*/}
            <p>#dakhwedding</p>
            <p className="footer__copy">All rights reserved &copy; 2019</p>
          </div>
        </div>
      </div>
    </div>
  </footer>;
}