import React from "react";
import {weddingParty} from "./data";
import LazyLoad from "react-lazyload";

function WeddingPartyMember(props) {
  const {partyMember, personType} = props;
  const {name, image, subText} = partyMember;

  return <li className="slider-list__item js-slide">
    <LazyLoad height={200}>
      <img src={image}/>
    </LazyLoad>
    <p className="font-weight-bold">{name}</p>
    <p>{subText ? subText : personType}</p>
  </li>;
}

export function Friends() {

  return <section className="friends js-section" id="friends">
    <h2 className="u-font-script text-center u-m-b-5">our party</h2>
    <ul className="slider-list js-slider">
      {weddingParty.bridesmaids.map(partyMember =>
        <WeddingPartyMember
          key={partyMember.name}
          partyMember={partyMember}
          personType={"bridesmaid"}
        />
      )}

      {weddingParty.groomsmen.map(partyMember =>
        <WeddingPartyMember
          key={partyMember.name}
          partyMember={partyMember}
          personType={"groomsman"}
        />
      )}
    </ul>
    <div className="d-none d-lg-flex slider-arrows js-slider-arrows">
			<span className="slider-arrow js-arrow-prev">
				<svg width="40" height="10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 10">
          <path fill="none" d="M5 10L1 5l4-5M40 5H1.5"/></svg>
			</span>
      <ion-icon name="heart" class="icon icon--sm icon--primary"/>
      <span className="slider-arrow js-arrow-next">
				<svg width="40" height="10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 10">
          <path fill="none" d="M36 0l4 5-4 5M1 5h38.5"/></svg>
			</span>
    </div>
  </section>;
}