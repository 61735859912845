import React, {useEffect} from "react";
import ScriptTag from 'react-script-tag';

export function Memories() {
  useEffect(()=>{
    setTimeout(()=>{
      const {$} = window;

      /*==== 4. Masonry gallery ====*/
      const $grid = $('.grid').masonry({
        itemSelector: '.grid-item',
        //columnWidth: '.grid-sizer',
        gutter: '.gutter-sizer',
      });

      // layout Masonry after each image loads
      $grid.imagesLoaded().progress( function() {
        $grid.masonry('layout');
      });
    }, 500);

  }, []);

  return <section className="memories js-section" id="memories">
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <h2 className="u-font-script text-center u-m-b-5">our memories
            <ion-icon name="heart" class="icon icon--sm icon--primary"/>
          </h2>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className=" grid">
            <div className="grid-sizer"/>
            <div className="gutter-sizer"/>
            <div className="grid-item">
              <img src="/images/gallery/ACS_0046.jpg" className="memories__img" />
            </div>
            <div className="grid-item">
              <img src="/images/gallery/IMG_1465.jpg" className="memories__img" />
            </div>
            <div className="grid-item">
              <img src="/images/gallery/IMG_2856.jpg" className="memories__img" />
            </div>
            <div className="grid-item">
              <img src="/images/gallery/IMG_2905.jpg" className="memories__img" />
            </div>
            <div className="grid-item">
              <img src="/images/gallery/IMG_2938.jpg" className="memories__img" />
            </div>
            <div className="grid-item">
              <img src="/images/gallery/IMG_3308.jpg" className="memories__img" />
            </div>
            <div className="grid-item">
              <img src="/images/gallery/IMG_3366.jpg" className="memories__img" />
            </div>
            <div className="grid-item">
              <img src="/images/gallery/IMG_3445.jpg" className="memories__img" />
            </div>
            <div className="grid-item">
              <img src="/images/gallery/IMG_3821.jpg" className="memories__img" />
            </div>
            <div className="grid-item">
              <img src="/images/gallery/IMG_4201.png" className="memories__img" />
            </div>
            <div className="grid-item">
              <img src="/images/gallery/IMG_4761.jpg" className="memories__img" />
            </div>
            <div className="grid-item">
              <img src="/images/gallery/IMG_4834.jpg" className="memories__img" />
            </div>
            <div className="grid-item">
              <img src="/images/gallery/IMG_5602.jpg" className="memories__img" />
            </div>
            <div className="grid-item">
              <img src="/images/gallery/IMG_5782.jpg" className="memories__img" />
            </div>
            <div className="grid-item">
              <img src="/images/gallery/IMG_5797.jpg" className="memories__img" />
            </div>
            <div className="grid-item">
              <img src="/images/gallery/IMG_5841.jpg" className="memories__img" />
            </div>
            <div className="grid-item">
              <img src="/images/gallery/IMG_6106.png" className="memories__img" />
            </div>
            <div className="grid-item">
              <img src="/images/gallery/IMG_6135.png" className="memories__img" />
            </div>
            <div className="grid-item">
              <img src="/images/gallery/IMG_8975.jpg" className="memories__img" />
            </div>
            <div className="grid-item">
              <img src="/images/gallery/IMG_9121.jpg" className="memories__img" />
            </div>

            <ScriptTag type="text/javascript" src={"https://unpkg.com/masonry-layout@4/dist/masonry.pkgd.min.js"}/>
            <ScriptTag type="text/javascript" src={"https://unpkg.com/imagesloaded@4/imagesloaded.pkgd.min.js"}/>
          </div>
        </div>
      </div>
    </div>
  </section>;
}