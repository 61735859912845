import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import App from "./App";
import Rick from "./Rick";
import GuestList from "./GuestList";

export default function routes () {
  return (
    <Router>
      <div>
        <Route exact path="/" component={App} />
        <Route path="/rick" component={Rick} />
        <Route path="/guestlist-5" component={GuestList} />
      </div>
    </Router>
  )
}