import React, {useEffect, useState} from 'react';
import {rsvpDb} from './db';

function GuestList() {
  const [list, setList] = useState([]);

  useEffect(async () => {
    let tempList = [];
    const snapshot = await rsvpDb.get();

    snapshot.forEach(doc => {
      tempList.push(doc.data());
    });

    const sorted = tempList.sort((a, b) => {
      if (a.time > b.time || (!a.time && b.time)) {
        return 1;
      } else {
        return -1;
      }
    });

    setList(sorted);
  }, []);

  const totalGuests = list.reduce((total, cur) => (cur.cantMake?0:Number(cur.guestCount)) + total, 0);

  return (
    <div>
      <table border="1" cellPadding={6}>
        <tbody>
        {list.map(item => (
          <tr
            key={item.email}
            style={{backgroundColor: item.cantMake?"#ffa9a9":"#FFF"}}
          >
            <td>{item.name}</td>
            <td>{item.email}</td>
            <td>{item.guestCount}</td>
            <td>{item.guestMessage}</td>
          </tr>
        ))}
        </tbody>
      </table>

      <div>Total guests RSVP'd: {totalGuests}</div>
    </div>
  );
}

export default GuestList;