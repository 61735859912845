import React, {useState, useEffect} from 'react';
import './App.css';
//
// constructor(props) {
//   super(props);
//
//   this.state = {
//     screenWidth: window.innerWidth
//   }
// }
//
// resizeHandler() {
//   this.setState({
//     screenWidth: window.innerWidth
//   });
// }
//
// componentDidMount() {
//   window.addEventListener("resize", this.resizeHandler.bind(this));
// }
//
// componentWillUnmount() {
//   window.removeEventListener("resize", this.resizeHandler.bind(this));
// }

function App() {
  const screenWidth = useScreenWidth();

  return (
    <div className="App">
      {screenWidth}
    </div>
  );
}

function useScreenWidth() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const handler = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(()=>{
    window.addEventListener("resize", handler);

    return () => {
      window.removeEventListener("resize", handler);
    }
  });

  return screenWidth
}

export default App;