import React from "react";
import {Countdown} from "./Countdown";
import {introAbout} from "./data";
import {useScreenWidth} from "./hooks/useScreenWidth";
import LazyLoad from "react-lazyload";

export function Intro() {
  const screenWidth = useScreenWidth();

  return <section className="intro" id="intro">
    {/*<h2 className="u-font-script text-center u-m-b-5">U + ME = US</h2>*/}
    {/*<div className="container">*/}
      {/*<div className="row">*/}
        {/*<div className="col-md-12">*/}
        {/*</div>*/}
      {/*</div>*/}
      {/*<div className="row">*/}
        {/*<div className="col-md-12">*/}
          {/*<div className="intro-content d-lg-flex">*/}
            {/*<div className="intro-content__col">*/}
              {/*<div*/}
                {/*className="intro-content__img"*/}
                {/*style={{backgroundImage: "url('/images/sarah.jpg')"}}*/}
              {/*/>*/}
              {/*/!*<div className="intro-content__text u-bg-primary d-flex align-items-center">*!/*/}
                {/*/!*<div>*!/*/}
                  {/*/!*<p*!/*/}
                    {/*/!*style={{*!/*/}
                      {/*/!*lineHeight: "18px"*!/*/}
                    {/*/!*}}*!/*/}
                  {/*/!*>*!/*/}
                    {/*/!*{introAbout.sarah}*!/*/}
                  {/*/!*</p>*!/*/}
                {/*/!*</div>*!/*/}
              {/*/!*</div>*!/*/}
            {/*</div>*/}
            {/*{screenWidth >= 992 ? (*/}
              {/*<ion-icon*/}
                {/*ame="heart"*/}
                {/*class="icon icon--xl icon--white"*/}
              {/*/>) : null}*/}
            {/*<div className="intro-content__col">*/}
              {/*<LazyLoad height={280}>*/}
                {/*<div className="intro-content__img"*/}
                     {/*style={{backgroundImage: "url('/images/steve.jpg')"}}*/}
                {/*/>*/}
              {/*</LazyLoad>*/}
              {/*/!*<div className="intro-content__text u-bg-grey d-flex align-items-center">*!/*/}
                {/*/!*<div>*!/*/}
                  {/*/!*<p*!/*/}
                    {/*/!*style={{*!/*/}
                      {/*/!*lineHeight: "18px",*!/*/}
                      {/*/!*paddingTop: 20*!/*/}
                    {/*/!*}}*!/*/}
                  {/*/!*>*!/*/}
                    {/*/!*{introAbout.steve}*!/*/}
                  {/*/!*</p>*!/*/}
                {/*/!*</div>*!/*/}
              {/*/!*</div>*!/*/}
            {/*</div>*/}
          {/*</div>*/}
        {/*</div>*/}
      {/*</div>*/}

    {/*</div>*/}

    <div
      className={"u-bg-grey"}
      style={{padding: 40, marginTop: 0}}
    >
      <h2
        className="text-center u-font-script"
      >
        we are counting down!
      </h2>

      <Countdown/>
    </div>

  </section>;
}
