import React from "react";

const styles = {
  p: {
    fontFamily: "helvetica, san-serif",
    marginBottom: 10
  }
};

export const storyDays = [
  {
    time: "2017-07-23 23:30",
    sarah: `Is having a few too many drinks. She books flights to NYC, leaving in 3 days. She's not aware of her impulsive purchase.`,
    steve: `Is shooting zombies in virtual reality.`
  },
  {
    time: "2017-07-26 10:00",
    sarah: `Boards her Qantas flight. She is excited to meet up with her cousin, so they can sexy dance to gangster music in New York.`,
    steve: `Is eating pepperoni pizza.`
  },
  {
    time: "2017-07-30 11:00",
    sarah: `Says goodbye to her cousin and checks into an Airbnb of her nightmares. She considers bailing, but the host takes her out for drinks and tells her good stories.`,
    steve: `Is counting his cryptocurrencies.`
  },
  {
    time: "2017-08-03 15:00",
    sarah: `Is flicking through Bumble to see what NYC has to offer`,
    steve: `Arrives to Indigo hotel with crypto friends.`
  },
  {
    time: "2017-08-04 13:30",
    sarah: `Is shopping for vintage clothes `,
    steve: `Is eating smoked salmon with Rick. Rick is friends with Sarah's Airbnb host and invites her to join them. He also invites her to an apartment party later on.`
  },
  {
    time: "2017-08-04 19:00",
    sarah: `Receives text message from Airbnb host, asking her to come to an apartment for drinks.`,
    steve: `Doing shots and coding.`
  },
  {
    time: "2017-08-04 21:00",
    sarah: `Sarah arrives at Indigo hotel. She is escorted to the penthouse apartment. She is distracted by the 360 view of Manhattan. She also notices a room with some nerds. Sarah feels safe, because nerds don’t hit on girls.`,
    steve: `Steve notices a koala entering the apartment. Steve thinks koala is pretty.`
  },
  {
    time: "2017-08-04 21:15",
    sarah: `After taking several selfies on the balcony, Sarah decides to introduce herself to the nerds.`,
    steve: `Says hello.`
  },
  {
    time: "2017-08-04 21:16",
    sarah: `Questions why they’re all together in such a huge apartment.`,
    steve: `Responds that he and his friends are in the cryptocurrency space. He says that they’re all catching up and New York is central for them. `
  },
  {
    time: "2017-08-04 21:17",
    sarah: `Tells Steve that she knows what cryptocurrency is and invested $5000 into Ethereum only 4 months ago.`,
    steve: `His tastebuds are tantalised. He tells Sarah that he and others in the apartment are co-founders of Ethereum`
  },
  {
    time: "2017-08-04 21:18",
    sarah: `Sarah uses the opportunity to ask him why the Ethereum team haven’t made her any money yet.`,
    steve: `Steve says HODL.`,
    //
    // sarah: "Is intrigued by this nerd, but curious as to whether he's interested in anything more than computers' "
    // steve: "pours two vodka shots"
  }
];

export const activities = [
  {
    name: "Activities",
    description: <div>
      <p>This one is for all those who don’t live in NYC.</p>
      <p>Firstly, there are endless activities to do in amongst drinking and experiencing delicious restaurants… We
        will however, provide you with some of the things that Steve and I enjoy doing with our spare time.</p>
    </div>
  },
  {
    name: "West Village",
    description: <div>
      <p>West Village is a great area to explore the old, beautiful pre-war buildings and incredible history that it has
        preserved so well. </p>

      <p>Stroll along Bleecker street, to see all the wonderful retail stores. Make sure to stop into Murrays Cheese and
        wine bar. Buvette is also a great lunch spot. </p>

      <p>Love Jazz music? Our favourite jazz bar is Mezzrow. It’s super intimate and an absolute gem for those avid
        lovers. Otherwise, Smalls is a little less intimate, but still a cool experience. </p>

      <p>Our favourite lunch restaurants are Fairfax for a loungy, easy bites vibe. Or Tartine for a very French,
        authentic experience. </p>

      <p>Our favourite dinner restaurants are Fedora for modern cuisine, or Casa for fresh Brazilian cuisine. </p>

      <p>Looking for drinks? Hotel Hugo for Mexican and tequila. They have a rooftop bar!</p>
    </div>
  },
  {
    name: "Chelsea/Meatpacking",
    description: <div>
      <p>This area is a mix of industrial, meets commercial. Everything is spread out, but there’s ample of things to
        do. It’s very hip. </p>

      <p>The Highline is a great starting point. It’s an old elevated rail line that has been re-established into a
        walking track and sometimes displays outdoor exhibitions and live music. </p>

      <p>Chelsea Markets are great for a feed and shop and are right near the highline! Otherwise, the trendy Barbuto
        Italian restaurant is in the area (it’s really fucking cool). </p>

      <p>Otherwise, explore the retail shops through the meatpacking district INTERMIX is a great starting point</p>

      <p>Feeling artsy? Check out the Whitney Museum. </p>
    </div>
  },
  {
    name: "Greenwich Village",
    description: <div>
      <p>This is our hood. We live in and love this area! It has a mix of young, old, conservative, crazy, pre-war, new,
        cool, cringe etc… </p>

      <p>Start off at Washington Square Park. We love sitting here, listening to live music and people watching. Then
        head south down Thompson or Sullivan street (or both), to explore the bohemian, grungy vibe. There’s a cute
        Australian café called Banter, if you’re after good eggs and coffee! </p>

      <p>This area is home of the famous Comedy Cellar, and many other (walk in) comedy clubs. Afterwards, stop by
        Minetta Tavern for a French dinner experience. </p>
    </div>
  },
  {
    name: "SOHO/Nolita",
    description: <div>
      <p>Further down you’ll step into Soho, which means fashion, fashion and trendy people. Prince Street and Spring
        Street both have great retail. West Broadway has many art galleries and the famous Broadway has all the
        commercial retail. Soho also has a few cafes worth mentioning The Mercer Kitchen and Antique Garage. There’s
        probably more, but we’ll let you figure that out. Oh The James Hotel has a rooftop for drinks.</p>

      <p>Next door, you have Nolita… This little, cute as fuck area is a MUST. It’s trendy, upscale, hipster, and has
        beautiful architecture. It has everything you pretty much want to be a part of. Get lost, walking around the
        streets (Mulberry, Mott, Elizabeth). Eat pizza at Prince Street Pizza. They’re famous. Or check out Pietro
        Nolita for cool as Instagram snaps. </p>
    </div>
  },
  {
    name: "East Village",
    description: <div>
      <p>For all the arty, hipster ‘I’m looking for culture’ type people, then east village is your thing. </p>

      <p>Start off at The Café Standard on Cooper Square, then stroll up to St Marks Place. Continue to walk down st
        marks, taking in all the grungy, eclectic scenery and shops. When you hit the end, walk over to Tompkins Square
        Park, to kick it.</p>

      <p>Not done with shopping/browsing. 10th street and 9th street are great for vintage shopping! </p>

      <p>Hungry? The famous Momofuku (ft. on Chefs Table) is in the area, along with the original dessert shop Milk
        Bar.</p>
    </div>
  },
  {
    name: "Lower East Side",
    description: <div>
      <p>This area is cool as fucking shit. Super boutique, super entrepreneurial, super grunge…</p>

      <p>Depending on the time of day that you explore the area, you could start off at Good Thanks Café on Orchard
        street, for some Aussie brunch/lunch. Then stroll up and down that street to check out the art galleries and
        boutique retail stores that you won’t find anywhere else in NYC. Their neighbouring streets Ludlow, Rivington
        and Stanton are all great for cruising around. Want more shopping? Head over to Broome (cross over
        Delancey). Kiki is also a great, little Greek restaurant! Very traditional. </p>

      <p>Keen for drinks? Indigo Hotel is the one. Still partying? Head to The Backroom for some speakeasy fun. Or
        Beauty and Essex for the most incredible dinner experience. </p>
    </div>
  },
  {
    name: "Midtown",
    description: <div>
      <p>As touristy as one can get! Not our cup of tea, but it does hold most the iconic New York things to do…</p>

      <p>Do go and see how terrible Time Square is. There’s something VERY New York about it that leaves you conflicted
        as to whether you love it or hate it. Otherwise you have the Empire State Building, Central Park, Plaza Hotel
        (there’s a bar in there), Rockefeller building, Bryant Park. </p>

      <p>If I haven’t mentioned an area, there’s probably a good reason why. Chinatown stinks and Little Italy, even
        though the concept is cool, it’s very touristy/they’ll rip you off. </p>
    </div>
  },
  {
    name: "More?",
    description: <div>
      <p>Need more food, activity suggestions? Or planning on heading over to Brooklyn?</p>

      <p>E-mail Sarah: sarah@blairecreative.com </p>
    </div>
  }
];

export const whereStay = [
  {
    name: "Hotel Ideas",
    description: <div>
      <p>
        The Foundry (our wedding location) is in Long Island City, and even though The
        Foundry is beautiful, the city itself is quite industrial. If you’re a person who is always
        late, then consider staying in Long Island City, however we’d rather recommend places
        to sleep in Manhattan.
      </p>
      <p>
        We’ve recommended hotels that are situated on the EAST side of Manhattan, as they
        have direct routes to Long Island City – this is important to consider, as Friday night
        traffic will be VERY average.
      </p>
    </div>
  },
  {
    name: "The Plaza Hotel",
    link: "https://www.theplazany.com/",
    linkDisplay: "theplazany.com",
    tagLine: "For all those ballers out there...",
    description: <div>
      <p>
        It’s expensive, but very lush! It also is right next to the famous Central Park and access
        to many subway lines.
      </p>
    </div>
  },
  {
    name: "The Freehand Hotel",
    link: "https://freehandhotels.com/new-york/",
    linkDisplay: "freehandhotels.com/new-york",
    tagLine: "For all those kids that don’t have to go far to enjoy their stay...",
    description: <div>
      <p>
        The Freehand Hotel is a hotel that keeps on providing. It has two super trendy
        restaurants, a cool as fuck bar and a rooftop bar for those who enjoy summertime
        rooftop drinks. It’s also a stone throw away from lower Manhattan.
      </p>
    </div>
  },
  {
    name: "The Gramercy Park Hotel",
    link: "http://www.gramercyparkhotel.com/",
    linkDisplay: "gramercyparkhotel.com",
    tagLine: "For all those sophisticated, classy and design lovers…",
    description: <div>
      <p>
        Gramercy Park is a very pretty, more delicate area of Manhattan. The hotel is beautifully
        executed, focusing on décor, art and classy characters. If you don’t stay here, then at
        least check out their award winning restaurant!
      </p>
    </div>
  },
  {
    name: "The Bowery Hotel",
    link: "https://www.theboweryhotel.com/",
    linkDisplay: "theboweryhotel.com",
    tagLine: "For all those trendy, cool people...",
    description: <div>
      <p>
        The Bowery is known for its chicness attitude. The rustic lobby, and the fassshhion. It’s
        also getting closer to downtown/East Village, where the hipsters, hippies and edgy
        individuals lurk.
      </p>
    </div>
  },
  {
    name: "Ace Hotel",
    link: "https://www.acehotel.com/newyork/",
    linkDisplay: "acehotel.com/newyork",
    tagLine: "For all those arty hipster kids...",
    description: <div>
      <p>
        This hotel has a community if it owns. It’s very contemporary, yet provides
        accommodation with simplicity. Even though the rooms are slightly on the smaller side,
        there’s a really cool vibe here.
      </p>
    </div>
  },
  {
    name: "The Hotel Indigo",
    link: "https://www.ihg.com/hotelindigo/",
    linkDisplay: "ihg.com/hotelindigo",
    tagLine: "For all those who want to immerse themselves in the arty-grunge flavours of NYC.",
    description: <div>
      <p>
        The indigo hotel it situated in Lower East Side. Even though it’s not the closest option to
        the wedding, it's young, it's cool, it's hip and reflects this entrepreneurial lifestyle that we all envy.
      </p>
    </div>
  },
  {
    name: "The James Hotel in SoHo",
    link: "https://www.jameshotels.com/new-york-soho/",
    linkDisplay: "jameshotels.com/new-york-soho",
    tagLine: "For all those who AREN’T late people and happy to sit in traffic…",
    description: <div>
      <p>
        The James Hotel is another gem that has a cool, super hip rooftop bar with a pool! It’s
        also situated in the heart of Soho, so if you’re planning a week here, you’re in a
        prime/cool tourist area with access to many activities.
      </p>
    </div>
  },
  {
    name: "The Maritime Hotel",
    tagLine: "For all those who AREN’T late people and happy to sit in traffic…",
    link: "https://www.themaritimehotel.com/",
    linkDisplay: "themaritimehotel.com",
    description: <div>
      <p>
        The Maritime is where Sarah's paren't stay when they are in New York. It’s situated in the heart of Chelsea,
        next to the meatpacking district and just above the thriving West Village. It’s a really cool area,
        especially for first timers into NYC.
      </p>
    </div>
  },
  {
    name: "More Hotels",
    description: <div>
      <p>
        MIDTOWN = More touristy, more site seeing, more commercial, less character, less
        culture
      </p>
      <p>
        EAST VILLAGE = More culture, more rustic, more interesting characters, less tourism, less site
        seeing
      </p>
      <p>
        LOWER EAST SIDE = Super hipster, very young, lots of cool boutiques, bars and cafes. Very
        nightlife focused.
      </p>
    </div>
  },
  {
    name: "Even more...",
    description: <div>
      <p>
        Need more recommendations?
      </p>
      <p>
        E-mail Sarah: sarah@blairecreative.com
      </p>
    </div>
  }
];

export const storyIcons = {
  steve: "/images/steve_icon.jpg",
  sarah: "/images/sarah_icon.jpg"
};

export const weddingParty = {
  groomsmen: [
    {
      name: "Dan Mahler",
      image: "/images/partyMembers/dan.jpg",
      subText: "Best Man"
    },
    {
      name: "Eric Dakh",
      image: "/images/partyMembers/eric.jpg"
    },
    {
      name: "Arthur Yanishevsky",
      image: "/images/partyMembers/arthur.jpg"
    },
    {
      name: "Matt Masarsky",
      image: "/images/partyMembers/matt.jpg"
    },
    {
      name: "Ricardo D'Alessandro",
      image: "/images/partyMembers/rick.jpg"
    },
  ],
  bridesmaids: [
    {
      name: "Courtney Layfield",
      image: "/images/partyMembers/courtney.jpg",
      subText: "maid of honor"
    },
    {
      name: "Talitha Allen",
      image: "/images/partyMembers/talitha.jpg"
    },
    {
      name: "Charlotte Courtenay",
      image: "/images/partyMembers/chaz.jpg"
    },
    {
      name: "Jo White",
      image: "/images/partyMembers/jo2.jpg"
    },
    {
      name: "Caitlin Judd",
      image: "/images/partyMembers/caitlin.jpg"
    },
    {
      name: "Lexii",
      image: "/images/partyMembers/lexi.jpg",
      subText: "flower dog"
    },
    {
      name: "Hunter",
      image: "/images/partyMembers/hunter.jpg",
      subText: "ring bearer"
    }
  ]
};

export const introAbout = {
  sarah: (
    <div>
      <div style={styles.p}>
        Sarah is made up of:

        Matcha
        Questions
        Dark Chocolate
        Colourful EVERYthings
        Sweet Potato
        Steve
        Passion projects
        Lexii
        New Cities
      </div>


      <div style={styles.p}>
      </div>
    </div>
  ),
  steve: (
    <div>
      <div style={styles.p}>Steve was born curious and stayed curious. He started programming when he was six years old.
        Throughout adolescence, Steve spent almost all of his time at the computer.
      </div>

      <div style={styles.p}>Following his antisocial upbringing, he began spending time around people. He soon became
        just as curious about them as he was about technology. His newfound hobby? Observing social interaction.
      </div>

      <div style={styles.p}>At age 31 and succeeding in his crypto career, Steve met Sarah. After studying many human
        beings, he knew she was the one. They were engaged 6 months later.
      </div>

      <div style={styles.p}>Today he spends days working on interesting projects, thinking about the way the world
        works, and spending time with Sarah.
      </div>
    </div>
  )
};

export const faq = [
  {
    q: "Where is the Wedding?",
    a: (
      <div>
        The Foundry<br/>
        42-38 9th St<br/>
        Long Island City<br/>
        NY, 11101<br/>
      </div>
    )
  },
  {
    q: "When do we RSVP by?",
    a: (
      <div>
        March 25th 2019
      </div>
    )
  },
  {
    q: "What time should we get there?",
    a: (
      <div>
        5:30pm sharp. NYC traffic won’t be your best friend on a Friday afternoon, so be time savvy.
      </div>
    )
  },
  {
    q: "Are the ceremony and reception at the same location?",
    a: (
      <div>
        Yes, so kick back and enjoy the evening.
      </div>
    )
  },
  {
    q: "What time does the evening finish?",
    a: (
      <div>
        Roughly 12.30am.
      </div>
    )
  },
  {
    q: "What is the dress code?",
    a: (
      <div>
        Black-tie/dress to impress… Men always look better in a suit, right? As for the chicky babes, you can use this
        opportunity to finally pull that special dress out of your cupboard that you’ve been dying to wear to an event.
      </div>
    )
  },
  {
    q: "I’m thinking about renting my outfit. What should I do?",
    a: (
      <div>
        Women - <a href="https://www.renttherunway.com/" target={"_blank"}>renttherunway.com</a> <br/>
        Men - <a href="https://theblacktux.com/" target={"_blank"}>theblacktux.com</a>
      </div>
    )
  },
  {
    q: "Will it get cold?",
    a: (
      <div>
        Most likely no. Our event will be hosted inside and outside, so if you’re concerned then just bring a light
        shawl for your shoulders.
      </div>
    )
  },
  {
    q: "Is there a wedding registry?",
    a: (
      <div>
        Yes! The registry links are available on the website.
      </div>
    )
  },
  {
    q: "Is there valet parking?",
    a: (
      <div>
        Yes, just pull up to the front of The Foundry and a valet will take your car.
      </div>
    )
  },
  {
    q: "Can I bring my children?",
    a: (
      <div>
        As much as we love children, we’ve decided to make this an adults only event. Unless of course they feature in
        the wedding. If you need some sitter recommendations, please do not hesitate to reach out to us.
      </div>
    )
  },
  {
    q: "Can I bring a date?",
    a: (
      <div>
        In order to keep our guest list on track, we are only able to accommodate those guests formally invited on your
        wedding invitation. Please feel free to email us, however! We’d hate for you to feel sad and lonely without your
        loved one.
      </div>
    )
  },
  {
    q: "Will there be transport provided?",
    a: (
      <div>
        No. Uber, Lyft and the subway are your best bet.
      </div>
    )
  },
  {
    q: "Will there be food and drinks served?",
    a: (
      <div>
        Please come with an empty stomach. We will have passed appetizers, a full open bar, as well as a family style
        dinner, wedding cake and dessert. Food is life.
      </div>
    )
  },
  {
    q: "What if I have a dietary restriction?",
    a: (
      <div>
        Please email us at sarah@blairecreative.com It’s VERY important that we know this information, so that we can
        accommodate you on the evening!
      </div>
    )
  },
  {
    q: "Will there be dancing and entertainment?",
    a: (
      <div>
        Of course! This will give Sarah and Steve an opportunity to show off their “awesome” dance moves…
      </div>
    )
  },
  {
    q: "Other questions?",
    a: (
      <div>
        Please email sarah@blairecreative.com
      </div>
    )
  }
];