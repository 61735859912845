import React from "react";
import moment from 'moment';
import {storyIcons} from "./data";

const styles = {
  time: {
    marginBottom: 20,
    fontSize: 18,
  },
  person: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 20,
    fontSize: 16
  },
  container: {
    marginBottom: 20,
  },
  image: {
    minWidth: 50,
    height: 50,
    marginRight: 20,
    borderRadius: 50
  },
  middle: {
    marginTop: 20
  }
};

export function StoryDay(props) {
  const {item} = props;
  const {time, sarah, steve} = item;

  return <div style={styles.container}>
    <h6>{moment(time).format("LLL")}</h6>

    <div style={styles.middle}>
      <div style={styles.person}>
        <div style={{
          ...styles.image,
          background: `url(${storyIcons.sarah})`,
          backgroundSize: "cover"
        }}/>
        <p>{sarah}</p>
      </div>

      <div style={styles.person}>
        <div style={{
          ...styles.image,
          background: `url(${storyIcons.steve})`,
          backgroundSize: "cover"
        }}/>
        <p>{steve}</p>
      </div>
    </div>
  </div>;
}