import React from "react";

export function Quote(props) {
  const {quote} = props;

  return <section className="quote u-bg-grey">
    <div className="container">
      <div className="row">
        <div className="col-md-10 offset-md-1">
          <h3 className="quote__text font-italic">
            {quote}
            {/*If you walk into a dark room and you see something that looks like a snake, do you get scared? <br/>-Larry Silverstri*/}
            {/*New York is always a good idea -anonymous*/}
          </h3>
        </div>
      </div>
    </div>
  </section>;
}