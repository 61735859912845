import React, {Component} from 'react';
import './App.css';
import {Header} from "./Header";
import {Navigation} from "./Navigation";
import {Intro} from "./Intro";
import {Friends} from "./Friends";
import {Quote} from "./Quote";
import {Location} from "./Location";
import {Memories} from "./Memories";
import {RSVP} from "./RSVP";
import {Footer} from "./Footer";
import {OurStory} from "./OurStory";
import {WhereStay} from "./WhereStay";
import {Activities} from "./Activities";
import {ImageBlock} from "./ImageBlock";
import {Registry} from "./Registry";
import {FAQ} from "./FAQ";
import LazyLoad from "react-lazyload";

async function loadScript (scriptSrc) {
  return new Promise(resolve=>{
    const script = document.createElement("script");
    script.src = scriptSrc;
    document.body.appendChild(script);

    script.onload = ()=>{
      resolve();
    }
  });
}

class App extends Component {
  async componentDidMount() {
    const scripts = [
      "assets/js/vendors/jquery-3.3.1.min.js",
      "assets/js/vendors/bootstrap.min.js",
      "assets/js/vendors/jquery.countdown.min.js",
      "assets/js/vendors/slick.min.js",
      "https://unpkg.com/ionicons@4.4.2/dist/ionicons.js",
      "https://unpkg.com/aos@2.3.1/dist/aos.js",
      "assets/js/script.js"
    ];

    for (let script of scripts) {
      await loadScript(script);
    }

    // await loadScript("assets/js/vendors/jquery-3.3.1.min.js");
    // await Promise.all(scripts.map(script=>loadScript(script)));
    // await loadScript("assets/js/script.js");
  }

  render() {
    return (
      <div className="App">
        <Header/>

        <Navigation/>

        <Intro/>

        {/*<OurStory/>*/}

        <OurStory/>

        <Friends/>

        <Quote
          quote={"F*ck it, let's go to New York"}
        />

        <Location/>

        {/*<LazyLoad height={400} offset={200}>*/}
          <RSVP/>
        {/*</LazyLoad>*/}

        <Quote
          quote={"#dakhwedding"}
        />

        <Registry/>

        <WhereStay/>

        <ImageBlock
          image={"/images/graffiti-girl.jpg"}
        />

        <Activities/>

        <FAQ/>

        <LazyLoad height={600}>
          <Memories/>
        </LazyLoad>

        {/*<Triangles/>*/}

        <Footer/>
      </div>
    );
  }
}

export default App;
