import React from "react";
import LazyLoad from "react-lazyload";

export function Location() {
  return (
    <section className="location js-section" id="location">
      <h2 className="u-font-script text-center u-m-b-5">event</h2>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="location__text location__text--sm u-bg-grey ml-auto u-m-b-3" data-aos="zoom-in-right">
              {/*<h6 className="text-uppercase"></h6>*/}
              <h3>The Wedding</h3>

              <b>The Foundry</b><br/>
              <div>42-38 9th St.</div>
              <div>Long Island City, NY 11101</div>

              <p>5:30pm sharp</p>

              <b>Dress Code</b>
              <p>Black Tie babyyy. Get excited! We’re getting sophisticated and classy.</p>

              <b>Travel By Train</b>
              <p>E,F,M,R,N,7</p>

              <p>You can get to The Foundry using the above trains. Consult Google Maps for detailed directions.</p>

            </div>

            <LazyLoad height={480}>
              <img src="/images/foundry-table.jpg" className="location__img location__img--sm" alt="Location"
                   data-aos="zoom-in-left"/>
            </LazyLoad>
          </div>

          <div className="col-md-6">
            <div className="location__text location__text--sm u-bg-grey" data-aos="zoom-in-left">
              {/*<h6 className="text-uppercase">After Party</h6>*/}
              <h3>After Party</h3>
              <p>
                <p>
                  <div>We will be keeping the party going at the foundry in the pool room until 2:30am.</div>
                  {/*<div>23 Lexington Ave.</div>*/}
                  {/*<div>New York, NY 10010</div>*/}
                  {/*<div>5:30pm sharp</div>*/}
                </p>
                <p>
                  <div>There will be late-night snacks and plenty of beverages!</div>
                </p>
                {/*<p>*/}
                {/*<div><b>Broken Shaker</b></div>*/}
                {/*<div>23 Lexington Ave.</div>*/}
                {/*<div>New York, NY 10010</div>*/}
                {/*<div>5:30pm sharp</div>*/}
                {/*</p>*/}

              </p>
            </div>

            <LazyLoad height={385}>
              <img src="/images/pool-room.jpg" className="location__img" alt="Location" data-aos="zoom-in-right"/>
            </LazyLoad>
          </div>
        </div>
      </div>
    </section>
  );
}