import React from "react";

import {WhereBlock} from "./WhereBlock";
import {PagingBlock} from "./PagingBlock";
import {activities} from "./data";

export function Activities() {

  return <div style={{marginTop: 80}}>
    <PagingBlock
      id={"activities"}
      items={activities}
      ItemComponent={WhereBlock}
      title={"activities"}
    />
  </div>;
}

