import React, {useState} from "react";

const styles = {
  button: {
    padding: 10,
    backgroundColor: "#ee9669",
    color: "#FFF",
    display: "inline-block",
    borderRadius: 4,
    cursor: "pointer",
    userSelect: "none",
    marginRight: 10,
    fontWeight: 400
  },
  buttonHolder: {
    textAlign: "right"
  }
};

export function PagingBlock(props) {
  const {ItemComponent, items, title, id, onNextPress} = props;
  const [index, setIndex] = useState(0);

  const innerStyle = {
    padding: 30,
    margin: "0 auto",
    maxWidth: 600
  };

  return <section id={id} className={"js-section"}>
    <h2 className="u-font-script text-center u-m-b-5">{title}</h2>

    <div className="u-bg-grey" style={innerStyle}>
      <ItemComponent
        item={items[index]}
      />

      <div style={styles.buttonHolder}>
        {(index - 1 >= 0) ? <div
          style={styles.button}
          onClick={() => {
            setIndex(index - 1);
          }}>Back</div> : null}

        {(index + 1 < items.length) ? <div
          style={styles.button}
          onClick={() => {
            if (onNextPress) onNextPress();

            setIndex(index + 1);
          }}>Keep going</div> : null}
      </div>
    </div>
  </section>;
}