import {useEffect, useState, useRef} from "react";

export function useScreenWidth() {
  const [width, setWidth] = useState(window.innerWidth);
  let running = useRef(false);

  const handler = () => {
    if (running.current) {
      return false;
    }

    running.current = true;

    setTimeout(() => {
      setWidth(window.innerWidth);
      running.current = false;
    }, 80);
  };

  useEffect(() => {
    window.addEventListener("resize", handler);

    return () => {
      window.removeEventListener("resize", handler);
    };
  }, []);

  return width;
}