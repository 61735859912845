import React from "react";
import {Slide} from "./Slide";

export function Header() {
  return <header>
    <div className="js-hero-slider">
      <Slide image={"/images/slider1.jpg"} position={"right"}/>
      <Slide image={"/images/slider2.jpg"}/>
      <Slide image={"/images/slider4.jpg"}/>
      <Slide image={"/images/slider3.jpg"}/>
    </div>
  </header>;
}