import React from "react";
import LazyLoad from 'react-lazyload';

const styles = {
  block: {
    height: 360,
    borderTop: "1px solid #B3B3B3",
    borderBottom: "1px solid #B3B3B3",
    marginTop: 40,
    color: "#FFF",
    backgroundRepeat: "no-repeat",
    fontFamily: '"Brandon Grotesque", sans-serif',
    textAlign: "center",
    fontSize: 40,
  },
  text: {
    marginTop: 219,
    textShadow: "1px 2px 3px #1b1b1b",
    backgroundColor: "rgba(0, 0, 0, 0.73)",
    lineHeight: "69px",
    // fontFamily: "'Josefin Sans', sans-serif",
    // fontWeight: "200",
  }
};

function ImageBlock(props) {
  const {image, yPosition, text, height, marginTop, textMargin} = props;

  let blockStyle = {
    ...styles.block,
    background: `url(${image}) center center`,
    backgroundSize: "cover",
    backgroundPositionY: !isNaN(yPosition) ? yPosition : "auto",
  };

  let extraTextStyle = {};

  if (textMargin) {
    extraTextStyle.marginTop = textMargin;
  }

  if (height) {
    blockStyle.height = height;
  }

  if (!isNaN(marginTop)) {
    blockStyle.marginTop = marginTop;
  }

  return (
    <LazyLoad height={400}>
      <div style={blockStyle}>
        <div style={{...styles.text, ...extraTextStyle}}>{text}</div>
      </div>
    </LazyLoad>
  );
}

ImageBlock.defaultProps = {
  yPosition: 0,
};

export {ImageBlock};