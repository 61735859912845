import React from "react";

import {WhereBlock} from "./WhereBlock";
import {PagingBlock} from "./PagingBlock";
import {whereStay} from "./data";

export function WhereStay() {
  const gtag = window.gtag;

  return <div style={{marginTop: 80}}>
    <PagingBlock
      id={"whereStay"}
      items={whereStay}
      ItemComponent={WhereBlock}
      title={"sleep"}
      onNextPress={() => {
        gtag('event', 'next', {
          'event_category': "whereStay"
        });
      }}
    />
  </div>;
}

