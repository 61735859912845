import React from "react";

const menu = [
  {
    id: "story",
    name: "Our Story"
  },
  {
    id: "location",
    name: "Event"
  },
  {
    id: "rsvp",
    name: "RSVP"
  },
  {
    id: "registry",
    name: "Registry"
  },
  {
    id: "whereStay",
    name: "Sleep"
  },
  {
    id: "activities",
    name: "Activities"
  },
  {
    id: "faq",
    name: "More Info"
  },
];

export function Navigation() {
  return <>
    <div className="nav u-border-b-grey d-none d-md-block">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <ul className="nav-list">
              {menu.map(item=> <li><a href={`#${item.id}`} className="nav-list__link js-nav-link">{item.name}</a></li>)}
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div className="mobile-nav d-md-none">
      <div className="mobile-nav__trigger">
        <a href="#" className="js-open-menu">
          <ion-icon name="menu" className="icon icon--md icon--white" style={{color: "rgb(238, 150, 105)", fontSize: 28}}/>
        </a>
      </div>
      <ul className="mobile-nav-list js-menu">
        {menu.map(item=> <li><a href={`#${item.id}`} className="mobile-nav-list__link js-nav-link">{item.name}</a></li>)}
      </ul>
    </div>
  </>;
}