import React from "react";

import {StoryDay} from "./StoryDay";
import {PagingBlock} from "./PagingBlock";
import {storyDays} from "./data";

export function OurStory() {
  const gtag = window.gtag;

  return <PagingBlock
    id={"story"}
    items={storyDays}
    ItemComponent={StoryDay}
    title={"our story"}
    onNextPress={() => {
      gtag('event', 'next', {
        'event_category': "story"
      });
    }}
  />;
}

